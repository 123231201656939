
import Vue from 'vue'
export default Vue.extend({
    props: ['urlBlog'],
    data () {
        return {
            hrefIframe: `https://www.facebook.com/plugins/share_button.php?href=${this.urlBlog}&layout=button&size=small&appId=726307808658920&width=76&height=20`
        }
    }
})
