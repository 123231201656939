
import TestimonialItem from '@/components/TestimonialItem.vue'
export default {
    components: {
        TestimonialItem
    },

    data() {
        return {
            swiperOption: {
                slidesPerView : 3,
                centeredSlides: true,
                loop: true,
                grabCursor: true,
                speed: 1000,
                spaceBetween : 50,
                autoHeight: true,
                autoplay: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    1499:{
                        slidesPerView : 3
                    },

                    768:{
                        slidesPerView : 2
                    },
                    320:{
                        slidesPerView : 1
                    }
                }
            },

            testimonialData: [
                {
                    id: 1,
                    text: "Dịch vụ tốt, giá cả hợp lý. Lần sau tôi sẽ quay lại.",
                    imgSrc: "/images/testimonial/author-1.webp",
                    name: "Trần Mạnh Hoàng",
                    content: 'Dịch vụ tốt, tư vấn nhiệt tình. Nhanh nhẹn!'
                },
                {
                    id: 2,
                    text: "Nhân viên tư vấn nhiệt tình. Giá cả hợp lý!",
                    imgSrc: "/images/testimonial/author-2.webp",
                    name: "Nguyễn Tiến Đạt",
                    content: 'Xử lý hồ sơ nhanh chóng, giá cả hợp lý!'
                },
                {
                    id: 3,
                    text: "Dịch vụ đa dạng, nhân viên nhiệt tình.",
                    imgSrc: "/images/testimonial/author-3.webp",
                    name: "Nguyễn Tấn Minh",
                    content: 'Thông tin tư vấn rõ ràng, nhiều sự lựa chọn.'
                }
            ]
        }
    },
};
