import { render, staticRenderFns } from "./_slug.vue?vue&type=template&id=4fe61d62&"
import script from "./_slug.vue?vue&type=script&lang=ts&"
export * from "./_slug.vue?vue&type=script&lang=ts&"
import style0 from "./_slug.vue?vue&type=style&index=0&id=4fe61d62&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/TheHeader.vue').default,OffCanvasMobileMenu: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/OffCanvasMobileMenu.vue').default,SearchPopup: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/SearchPopup.vue').default,BreadcrumbOne: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/BreadcrumbOne.vue').default,Comment: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/Comment.vue').default,BlogSidebar: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/BlogSidebar.vue').default,TheFooter: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/TheFooter.vue').default})
