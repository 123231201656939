
import Vue from 'vue';
import _ from 'lodash';
import { PostEnum } from '~/core/enum';

export default Vue.extend({
    props: ['blogs', 'blogType'],
    data() {
        return {
            blogsData: []
        }
    },
    computed: {
        filterBlogsByType () {
            const result = new Set();
            if (this.blogType === PostEnum.NEWS) {
                const source = this.blogs.filter(x => x.type === PostEnum.NEWS);
                do {
                    const item = _.sample(source);
                    item.typeName = 'Tin tức';
                    item.path = '/news'
                    result.add(item);
                } while (result.size < 5)
            }
            else {
                const source = this.blogs.filter(x => x.type !== PostEnum.NEWS);
                do {
                    const item = _.sample(source);
                    item.typeName = item.type === PostEnum.FEE ? 'Phí dịch vụ' : item.type === PostEnum.ABOUT ? 'Giới thiệu dịch vụ' : 'Du học các nước'
                    item.path = item.type === PostEnum.FEE ? '/fees' : item.type === PostEnum.ABOUT ? '/introduces' : item.type === PostEnum.OTHER ? '/others' : '/news'
                    result.add(item);
                } while (result.size < 6)
            }

            return [...result]
        },
        filterCategories () {
            const categories = new Set(this.blogs.map(x=>x.type));
            const result = []
            categories.forEach(x=> {
                const item = {
                    typeName: x === PostEnum.FEE ? 'Phí dịch vụ' : x === PostEnum.ABOUT ? 'Giởi thiệu dịch vụ' : x === PostEnum.OTHER ? 'Du học các nước' : 'Tin tức',
                    count: this.blogs.filter(xx => xx.type === x).length,
                    type: x,
                    path: x === PostEnum.FEE ? '/fees' : x === PostEnum.ABOUT ? '/introduces' : x === PostEnum.OTHER ? '/others' : '/news',
                }

                result.push((item));
            })

            return result;
        }
    },
    mounted () {
    }
})
