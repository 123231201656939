
import ContactUs from '@/components/CallToMe.vue'
import _ from 'lodash'
export default {
    components:{
        ContactUs
    },
    data(){
        return{
            isProd: process.env.NODE_ENV === 'production' ? true : false,
            isFeedbackPage: false
        }
    },
    head() {
        const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
        return {
            htmlAttrs: {
                ...i18nHead.htmlAttrs,
            },
            link: [
                ...i18nHead.link
            ]
        }
    },
    mounted () {
        this.updateIFrameTags();
        const {name} = this.$route;
        this.isFeedbackPage = !_.isNil(name) && !_.isEmpty(name) && name.includes('feedback');
    },
    updated () {
        this.updateIFrameTags();
    },
    methods: {
        updateIFrameTags() {
            const iframes = document.querySelectorAll('iframe');
            if (iframes && iframes.length > 0) {
                for (let index = 0; index < iframes.length; index++) {
                    const element = iframes[index];
                    if (element) {
                        element.setAttribute('title' ,`title ${index}`);
                    }
                }
            }
        }
    }
}
