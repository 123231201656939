
import TheHeader from '@/components/TheHeader.vue'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu.vue'
import SearchPopup from '@/components/SearchPopup.vue'
import BreadcrumbOne from '@/components/BreadcrumbOne.vue'
import BlogGridItem from '@/components/BlogGridItem.vue'
import CallToActionTwo from '@/components/CallToActionTwo.vue'
import TheFooter from '@/components/TheFooter.vue'
import _ from 'lodash'
export default {
    layout: 'default',
    components: {
        TheHeader,
        OffCanvasMobileMenu,
        SearchPopup,
        BreadcrumbOne,
        BlogGridItem,
        CallToActionTwo,
        TheFooter,
    },
    asyncData({req, res, store})
    {
        const source = store.state.postAboutServices

        let blogs = _.orderBy(source, ['type', 'viewNo'], ['asc', 'desc']);
        return {
            blogData: blogs,
            otherNews: store.state.otherNews,
            navigateItems: store.state.navigations,
            typeName: 'Giới thiệu dịch vụ'
        }
    },
    data() {
        return {
            items: []
        }
    },
    head() {
        return {
            title: this.typeName,
            meta: [
                {
                    property: 'description',
                    hid: 'description',
                    name: 'description',
                    content: this.$t('META.DESCRIPTION')
                },
                {
                    property: 'image',
                    hid: 'image',
                    name: 'image',
                    content: 'https://dichvubanker.com/images/hero-image/background-1.webp'
                },
                { 
                    name: 'Abstract', 
                    content: this.$t('META.DESCRIPTION')
                },
                {
                    property: 'og:description',
                    name: 'og:description',
                    content: this.$t('META.DESCRIPTION')
                },
                {
                    property: 'og:title',
                    name: 'og:title',
                    content: 'Giới thiệu dịch vụ'
                },
                {
                    property: 'og:image',
                    name: 'og:image',
                    content: 'https://dichvubanker.com/images/hero-image/background-1.webp'
                },
                {
                    property: 'og:site_name',
                    name: 'og:title',
                    content: 'Giới Thiệu Dịch Vụ - Cung Cấp Hỗ Trợ Dịch Vụ Tài Chính - Ngân Hàng - Dịch Vụ Hỗ Trợ Làm Visa'
                },
                {
                    property: 'og:url',
                    name: 'og:url',
                    content: 'https://dichvubanker.com/blog/introduces'
                },
                {
                    property: 'og:type',
                    name: 'og:type',
                    content: 'website'
                },
                {
                    name: "keywords",
                    content: this.$t('META.KEYWORDS')
                }
            ]
        }
    },
    created () {
        this.items = [
                {
                    text: 'Trang chủ',
                    to: "/"
                },
                {
                    text: this.typeName,
                    active: true
                }
            ]
    },
    mounted () {
        if (process.env.NODE_ENV === 'production') {
            this.$ga.page(this.$router);
        }
    }
};
