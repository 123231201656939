
import Vue from 'vue'
import ShapeWithAnimation from '@/components/ShapeWithAnimation.vue'
import _ from 'lodash'
export default Vue.extend({
    components: {
        ShapeWithAnimation
    },
    data() {
        return {
            inputObject: {
                fullName: {
                    text: '',
                    hasError: false,
                    message: '',
                    isReset: false
                },
                phoneNo: {
                    text: '',
                    hasError: false,
                    message: '',
                    isReset: false
                },
                note: {
                    text: '',
                    hasError: false,
                    message: '',
                    isReset: false
                },
            },
            objectReset: {}
        }
    },
    mounted () {
        Object.assign(this.objectReset, _.cloneDeep(this.inputObject))
    },
    methods: {
        async registerFor() {
            this.$axios.setToken(this.$store.getters.getToken, 'Bearer')

            const {post} = this.$axios

            if (_.isEmpty(this.inputObject.fullName.text)) {
                this.inputObject.fullName.message = 'Trường này là bắt buộc!';
                this.inputObject.fullName.hasError = true;
            }

            if (_.isEmpty(this.inputObject.phoneNo.text)) {
                this.inputObject.phoneNo.message = 'Trường này là bắt buộc!';
                this.inputObject.phoneNo.hasError = true;
            }

            if (this.inputObject.note.hasError || this.inputObject.phoneNo.hasError || this.inputObject.fullName.hasError) {
                return;
            }

            try {
                const result = await post(`/api/Contact/registerInformationContact`, {
                    name: this.inputObject.fullName.text,
                    phoneNo: this.inputObject.phoneNo.text,
                    note: this.inputObject.note.text
                });
                if (result) {
                    Object.assign(this.inputObject, this.objectReset);
                    this.inputObject.note.isReset = true;
                    this.inputObject.fullName.isReset = true;
                    this.inputObject.phoneNo.isReset = true;
                    alert('Chúng tôi sẽ liên hệ với bạn sớm nhất.');
                }
                else {
                    alert('Hệ thống đang lỗi. Xin vui lòng thử lại sau vài phút!');
                }
            } catch {
                this.$router.push({path: '/oops'})
                return;
            }
        }
    },
    watch: {
        "inputObject.note.text": function(newData) {
            if (this.inputObject.note.isReset) {
                this.inputObject.note.isReset = false;
                return;
            }

            if (newData.length > 2000) {
                this.inputObject.note.message = 'Không quá 2000 kí tự!';
                this.inputObject.note.hasError = true;
                return;
            }
            this.inputObject.note.message = '';
            this.inputObject.note.hasError = false;
        },
        "inputObject.fullName.text": function(newData) {
            if (this.inputObject.fullName.isReset) {
                this.inputObject.fullName.isReset = false;
                return;
            }
            if (_.isEmpty(newData)) {
                this.inputObject.fullName.message = 'Trường này là bắt buộc!';
                this.inputObject.fullName.hasError = true;
                return;
            }

            if (newData.length > 200) {
                this.inputObject.fullName.message = 'Không quá 200 kí tự!';
                this.inputObject.fullName.hasError = true;
                return;
            }
            this.inputObject.fullName.message = '';
            this.inputObject.fullName.hasError = false;
        },
        "inputObject.phoneNo.text": function(newData) {
            if (this.inputObject.phoneNo.isReset) {
                this.inputObject.phoneNo.isReset = false;
                return;
            }
            const phoneRegex =  /^[0-9]{10}$/
            if (_.isEmpty(newData)) {
                this.inputObject.phoneNo.message = 'Trường này là bắt buộc!';
                this.inputObject.phoneNo.hasError = true;
                return;
            }

            if (!phoneRegex.test(newData)) {
                this.inputObject.phoneNo.message = 'Số điện thoại không hợp lệ!';
                this.inputObject.phoneNo.hasError = true;
                return;
            }

            if (newData.length > 10) {
                this.inputObject.phoneNo.message = 'Không quá 10 kí tự!';
                this.inputObject.phoneNo.hasError = true;
                return;
            }
            this.inputObject.phoneNo.message = '';
            this.inputObject.phoneNo.hasError = false;
        }
    }
})
