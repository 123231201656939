
    import countTo from 'vue-count-to';

    import funFacts from "~/data/funfacts.json"
    export default {
        components: {
            countTo
        },
        data () {
            return {
                funFacts,
                startVal: 0,
                isVisible: false
            }
        },
        methods: {
            visibilityChanged (isVisible) {
                this.isVisible = isVisible
            },
        },
    };
