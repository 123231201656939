import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8cffa282&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/TheHeader.vue').default,OffCanvasMobileMenu: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/OffCanvasMobileMenu.vue').default,SearchPopup: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/SearchPopup.vue').default,HeroFixedBanner: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/HeroFixedBanner.vue').default,AboutWebsite: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/AboutWebsite.vue').default,FeeServicesTable: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/FeeServicesTable.vue').default,BrandLogoCarousel: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/BrandLogoCarousel.vue').default,FeeServicesTable2: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/FeeServicesTable2.vue').default,PriceServicesSection: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/PriceServicesSection.vue').default,TestimonialSectionOne: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/TestimonialSectionOne.vue').default,BlogSectionOne: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/BlogSectionOne.vue').default,TheFooter: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/TheFooter.vue').default})
