import { render, staticRenderFns } from "./FeeServicesTable.vue?vue&type=template&id=0fcda460&scoped=true&"
import script from "./FeeServicesTable.vue?vue&type=script&lang=ts&"
export * from "./FeeServicesTable.vue?vue&type=script&lang=ts&"
import style0 from "./FeeServicesTable.vue?vue&type=style&index=0&id=0fcda460&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fcda460",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/SectionTitle.vue').default})
