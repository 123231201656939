
import Vue from 'vue';
import _ from 'lodash';
export default Vue.extend({
    props: ['postId'],
    data (){
        return {
            commentContent: {
                text: '',
                hasError: false,
                message: '',
                isReset: false
            },
            name: {
                text: '',
                hasError: false,
                message: '',
                isReset: false
            },
            email: {
                text: '',
                hasError: false,
                message: '',
                isReset: false
            },
            orderOption: '0',
            commentsUI: [],
            commentContentRoot: {},
            commentNameRoot: {},
            commentEmailRoot: {},
            postIdSelected: this.postId
        }
    },
    async mounted () {
        Object.assign(this.commentContentRoot, _.cloneDeep(this.commentContent));
        Object.assign(this.commentNameRoot, _.cloneDeep(this.name));
        Object.assign(this.commentEmailRoot, _.cloneDeep(this.email));
        try {
            await this.loadCommentsByPostId();
            if (this.orderOption === '0') {
                this.commentsUI = _.orderBy(this.commentsUI, ['submitDate'], ['desc'])
            }
        } catch {
            
        }
    },
    methods: {
        async postComment() {
            this.$axios.setToken(this.$store.getters.getToken, 'Bearer')
            const {post} = this.$axios

            if (_.isEmpty(this.commentContent.text)) {
                this.commentContent.message = 'Trường này là bắt buộc!';
                this.commentContent.hasError = true;
            }

            if (_.isEmpty(this.name.text)) {
                this.name.message = 'Trường này là bắt buộc!';
                this.name.hasError = true;
            }

            if (_.isEmpty(this.email.text)) {
                this.email.message = 'Trường này là bắt buộc!';
                this.email.hasError = true;
            }

            if (this.commentContent.hasError || this.name.hasError || this.email.hasError) {
                return;
            }

            try {
                const result = await post(`/api/Comment/newComment`, {
                    displayName: this.name.text,
                    email: this.email.text,
                    content: this.commentContent.text,
                    postId: this.postId
                })

                if (result) {
                    alert('Bình luận đang đợi duyệt.');
                    Object.assign(this.commentContent, this.commentContentRoot);
                    this.commentContent.isReset = true;
                    Object.assign(this.name, this.commentNameRoot);
                    this.name.isReset = true;
                    Object.assign(this.email, this.commentEmailRoot);
                    this.email.isReset = true;
                }
                else {
                    alert('Hệ thống đang lỗi. Xin vui lòng thử lại sau vài phút!');
                }
            } catch {
                this.$router.push({path: '/oops'})
                return;
            }
        },
        async loadCommentsByPostId () {
            if (!_.isNil(this.postIdSelected)) {
                this.$axios.setToken(this.$store.getters.getToken, 'Bearer')
                const {get} = this.$axios
                const {data} = await get(`api/Comment/${this.postIdSelected}/commentsByPostId`);
                this.commentsUI = data;
            }
        }
    },
    watch: {
        "orderOption": function(newData) {
            if (newData === '0') {
                this.commentsUI = _.orderBy(this.comments, ['submitDate'], ['desc'])
            }
            else {
                this.commentsUI = _.orderBy(this.comments, ['submitDate'], ['asc'])
            }
        },
        "commentContent.text": function(newData) {
            if (this.commentContent.isReset) {
                this.commentContent.isReset = false;
                return;
            }
            if (_.isEmpty(newData)) {
                this.commentContent.message = 'Trường này là bắt buộc!';
                this.commentContent.hasError = true;
                return;
            }

            if (newData.length > 500) {
                this.commentContent.message = 'Không quá 500 kí tự!';
                this.commentContent.hasError = true;
                return;
            }
            this.commentContent.message = '';
            this.commentContent.hasError = false;
        },
        "name.text": function(newData) {
            if (this.name.isReset) {
                this.name.isReset = false;
                return;
            }
            if (_.isEmpty(newData)) {
                this.name.message = 'Trường này là bắt buộc!';
                this.name.hasError = true;
                return;
            }

            if (newData.length > 100) {
                this.name.message = 'Không quá 100 kí tự!';
                this.name.hasError = true;
                return;
            }
            this.name.message = '';
            this.name.hasError = false;
        },
        "email.text": function(newData) {
            if (this.email.isReset) {
                this.email.isReset = false;
                return;
            }
            const emailRegex =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (_.isEmpty(newData)) {
                this.email.message = 'Trường này là bắt buộc!';
                this.email.hasError = true;
                return;
            }

            if (!emailRegex.test(newData)) {
                this.email.message = 'Địa chỉ email không hợp lệ!';
                this.email.hasError = true;
                return;
            }

            if (newData.length > 100) {
                this.email.message = 'Không quá 100 kí tự!';
                this.email.hasError = true;
                return;
            }
            this.email.message = '';
            this.email.hasError = false;
        }
    }
})
