
    import Parallax from 'parallax-js';
    
    export default {
        props: ['addClassName', 'imgSrc', 'dataDepth'],

        mounted () {
            var scene = document.querySelectorAll('.scene');
            if(scene) {
                scene.forEach((el, i) => {
                    new Parallax(el);
                });
            };
        },
    };
