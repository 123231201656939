
import TheHeader from '@/components/TheHeader.vue'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu.vue'
import SearchPopup from '@/components/SearchPopup.vue'
import BreadcrumbOne from '@/components/BreadcrumbOne.vue'
import CallToActionTwo from '@/components/CallToActionTwo.vue'
import BlogSidebar from '@/components/BlogSidebar.vue'
import TheFooter from '@/components/TheFooter.vue'
import Comment from '@/components/Comment.vue'
import SocialNetwork from '@/components/widgets/SocialNetwork.vue'
import _ from 'lodash'
import ShareAndLikeButton from '@/components/widgets/LikeAndSharePlugin.vue';
import { PostEnum } from '~/core/enum'
import CommentCount from '@/components/widgets/CommentCountPlugin.vue'

interface PostItem{
    title: String,
    description: String,
    content: String,
    imageUrl: String,
    slug: String,
    type: number
}
export default {
    components: {
        CommentCount,
        TheHeader,
        OffCanvasMobileMenu,
        SearchPopup,
        BreadcrumbOne,
        BlogSidebar,
        CallToActionTwo,
        TheFooter,
        Comment,
        SocialNetwork,
        ShareAndLikeButton
    },

    data() {
        return {
            title: '',
            networks: [
                { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', className: 'mr-3' },
                { network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', className: 'mr-0' }
            ],
            comments: []
        }
    },
    async asyncData({req, res, store, params, $axios, query, redirect})
    {
        const source = store.getters.getAllPosts
        $axios.setToken(store.getters.getToken, 'Bearer')
        const item = _.find<PostItem>(source, (x:PostItem) => {
            return x.slug === params?.slug
        });
        const breadcrumbItems = [
            {
                text: 'Trang chủ',
                to: "/",
                allowChange: false
            },
            {
                text: 'Phí dịch vụ',
                to: "/blog/fees",
                allowChange: true
            },
            {
                text: 'Chi tiết',
                active: true,
                allowChange: false
            }
        ]
        const breadcrumbItem = breadcrumbItems.find(x => x.allowChange)
        if (item?.type === PostEnum.FEE && breadcrumbItem) {
            breadcrumbItem.text = 'Phí dịch vụ'
        }
        else if (item?.type === PostEnum.ABOUT && breadcrumbItem) {
            breadcrumbItem.text = 'Giới thiệu dịch vụ'
            breadcrumbItem.to = `/blog/introduces`
        }
        else if (item?.type === PostEnum.NEWS && breadcrumbItem) {
            breadcrumbItem.text = 'Tin tức'
            breadcrumbItem.to = `/blog/news`
        }
        else if (item?.type === PostEnum.OTHER && breadcrumbItem) {
            breadcrumbItem.text = 'Du học Hàn Quốc'
            breadcrumbItem.to = `/blog/others`
        }
        if(!item)
        {
            redirect(301, '/not-found');
            return;
        }

        return {
            blogItem: item,
            otherNews: store.state.otherNews,
            navigateItems: store.state.navigations,
            items: breadcrumbItems,
            social: {
                url: `${process.env.NODE_ENV === 'production' ? `https://dichvubanker.com/blog/${item.slug}` : `/blog/${item.slug}`}`,
                title: item.title,
            },
            postSlug: item.slug,
            blogsSource: source,
            currentType: item?.type
        }
    },
    head() {
        let arrTags = []
        const tags:any[] = this.blogItem?.tags || [];
        tags.forEach(element => {
            arrTags.push({
                property: "article:tag",
                name: 'article:tag',
                content: element
            },)
        });
        const keywords:any[] = this.blogItem?.keywords || [];
        return {
            title: this.blogItem?.title || ``,
            meta: [
                {
                    name: "keywords",
                    content: keywords.join(", ")
                },
                {
                  property: 'description',
                  hid: 'description',
                  name: 'description',
                  content: this.blogItem?.description
                },
                { 
                    name: 'Abstract', 
                    content: this.blogItem?.title
                },
                {
                    property: 'og:description',
                    name: 'og:description',
                    content: this.blogItem?.description
                },
                {
                    property: 'og:title',
                    name: 'og:title',
                    content: this.blogItem?.title
                },
                {
                    property: 'og:site_name',
                    name: 'og:site_name',
                    content: this.blogItem?.title
                },
                {
                    property: 'og:image',
                    name: 'og:image',
                    content: this.blogItem?.imageUrl
                },
                {
                    property: 'og:url',
                    name: 'og:url',
                    content: process.env.NODE_ENV === 'production' ? 
                        `https://dichvubanker.com/blog/${this.blogItem?.slug}` : 
                        `http://localhost:8952/blog/${this.blogItem?.slug}`
                },
                {
                    property: 'og:type',
                    name: 'og:type',
                    content: 'website'
                },
                ...arrTags
            ]
        }
    },
    async mounted () {
        const {view} = this.$router.currentRoute.query;
        if (process.env.NODE_ENV === 'production' && _.isNil(view)) {
            this.$ga.page(this.$router);
        }
    },
    methods: {
        handleShowTags (arrTags: any[]) {
            if (!_.isNil(arrTags)) {
                if (arrTags.length <= 3) {
                    return arrTags.join(', ');
                }

                return `${arrTags.slice(0, 3).join(', ')} và ${arrTags.length - 3} thẻ khác.`
            }
        }
    }
};
