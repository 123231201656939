
import BlogGridItem from '@/components/BlogGridItem.vue'
import SectionTitle from '@/components/SectionTitle.vue'
export default {
    props: ['postsNews'],
    data() {
        return {
        }
    },

    components: {
        BlogGridItem,
        SectionTitle
    },
};
