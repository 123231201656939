
import TheHeader from '@/components/TheHeader.vue'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu.vue'
import SearchPopup from '@/components/SearchPopup.vue'
import HeroFixedBanner from '@/components/HeroFixedBanner.vue'
import BlogSectionOne from '@/components/BlogSectionOne.vue'
import PriceServicesSection from '@/components/PriceServicesSection.vue'
import TheFooter from '@/components/TheFooter.vue'
import BrandLogoCarousel from '@/components/BrandLogoCarousel.vue'
import TestimonialSectionOne from '@/components/TestimonialSectionOne.vue'
import FeeServicesTable from '@/components/FeeServicesTable.vue'
import FeeServicesTable2 from '@/components/FeeServicesTable2.vue'
import AboutWebsite from '@/components/AboutWebsite.vue'
import CMTCDN from '@/components/CMTCDoanhNghiep.vue'
import WidgetServiceAbout from '~/components/WidgetServiceAbout.vue'
export default {
  layout: 'default',
  components: {
    TheHeader,
    OffCanvasMobileMenu,
    SearchPopup,
    HeroFixedBanner,
    BlogSectionOne,
    TheFooter,
    BrandLogoCarousel,
    TestimonialSectionOne,
    PriceServicesSection,
    FeeServicesTable,
    AboutWebsite,
    FeeServicesTable2,
    CMTCDN,
    WidgetServiceAbout
  },
  asyncData({ req, res, store}){
    return {
      priceServicesInfo: store.state.postPriceServices,
      postsNews: store.state.postNews,
      otherNews: store.state.otherNews,
      navigateItems: store.state.navigations
    }
  },
  data(){
    return{
    }
  },
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      meta: [
        {
          property: 'description',
          hid: 'description',
          name: 'description',
          content: this.$t('META.DESCRIPTION')
        },
        {
          property: 'image',
          hid: 'image',
          name: 'image',
          content: 'https://dichvubanker.com/images/hero-image/background-1.webp'
        },
        { 
          name: 'Abstract', 
          content: this.$t('META.DESCRIPTION')
        },
        {
          property: 'og:description',
          name: 'og:description',
          content: this.$t('META.DESCRIPTION')
        },
        {
          property: 'og:title',
          name: 'og:title',
          content: 'Dịch Vụ Hỗ Trợ Chứng Minh Tài Chính - Du Học - Du Lịch Toàn Quốc'
        },
        {
          property: 'og:image',
          name: 'og:image',
          content: 'https://dichvubanker.com/images/hero-image/background-1.webp'
        },
        {
          property: 'og:site_name',
          name: 'og:title',
          content: 'Cung Cấp Dịch Vụ Hỗ Trợ Chứng Minh Tài Chính'
        },
        {
          property: 'og:url',
          name: 'og:url',
          content: 'https://dichvubanker.com'
        },
        {
          property: 'og:type',
          name: 'og:type',
          content: 'website'
        },
        {
          name: "keywords",
          content: this.$t('META.KEYWORDS')
        },
        ...i18nHead.meta
      ]
    }
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      this.$ga.page(this.$router);
    }
  }
};
