
import Vue from 'vue'
import _ from 'lodash'
export default Vue.extend({
    fetch () {
        const value = localStorage.getItem("notifyHeaderWidget");
        if (_.isNil(value) && process.env.notifyWidgetHeader) {
            this.isShow = true;
        }
        else if  (_.isNil(value) && !process.env.notifyWidgetHeader) {
            this.isShow = false;
        }
        else if  (!_.isNil(value) && process.env.notifyWidgetHeader && value === "false") {
            this.isShow = false;
        }
        //this.isShow = !_.isNil(value) && process.env.notifyWidgetHeader && value === "false" ? false :
    },
    data () {
        return {
            isShow: false
        }
    },
    methods: {
        closeNotify () {
            this.isShow = false;
            localStorage.setItem("notifyHeaderWidget", "false");
        }
    }
})
