
import Vue from 'vue'
import _ from 'lodash'
export default Vue.extend({
    props: ['slug'],
    methods: {
        buildUrl (slugItem) {
            const hostName = process.env.NODE_ENV === 'production' ? 'https://dichvubanker.com/blog' : 'http://localhost:8952/blog'
            return `${hostName}/${slugItem}`
        }
    }
})
