
import Vue from 'vue'
import footerData from '~/data/footer.json';
export default Vue.extend({
    data() {
        return {
            footerData
        }
    },
})
