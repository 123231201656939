
import Vue from 'vue'
export default Vue.extend({
    mounted(){
        
    },
    methods: {
        trackingCall () {
            try {
                gtag('config', 'AW-11041563885');
            } catch {
                
            }
        }
    }
})
