
import _ from 'lodash'
export default {
    props: ['navigations', 'posts'],
    data() {
        return {
            flattenData: []
        }
    },
    mounted() {
        const offCanvasNav = document.querySelector('#offcanvas-navigation');
        if(offCanvasNav){
            const offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');
            const anchorLinks = offCanvasNav.querySelectorAll('a');
            
            for (let i = 0; i < offCanvasNavSubMenu.length; i++){
                offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
            }

            const menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
            const numMenuExpand = menuExpand.length;

            for (let i = 0; i < numMenuExpand; i++) {
                menuExpand[i].addEventListener("click", (e) => {sideMenuExpand(e)});
            }

            for (let i = 0; i < anchorLinks.length; i++) {
                anchorLinks[i].addEventListener("click", () => {closeMobileMenu()});
            }

            const sideMenuExpand = (e) => {
                e.currentTarget.parentElement.classList.toggle('active');
            }
            const closeMobileMenu = () => {
                const offcanvasMobileMenu = document.querySelector('#offcanvas-mobile-menu');
                offcanvasMobileMenu?.classList.remove('active');
            }
        }
        this.flattenArray(this.navigations)
    },
    methods:{
        flattenArray (arr: any[]) {
            arr.map(x => {
                this.flattenData.push({
                    id: x.id,
                    name: x.name,
                    url: x.url,
                    hash: x.hash
                })

                if (x.hasChildren) {
                    this.flattenArray(x.children)
                }
            })
        },
        getUrlOtherPost (item) {
            if (!_.isNil(item.hash)) {
                return `#${item.hash}`
            }
            return item.url
        },
        clickPreventLevel0 (id) {
            const item = this.flattenData.find(x=>x.id === id);
            let element = null
            let height = 0
            if (item && item.name === 'Giới Thiệu') {
                this.$router.push('/#section-about-web');
                element = document.querySelector('#section-about-web');
                height = 75
                window.scrollTo({ top: element?.getBoundingClientRect().top + window.scrollY - height, behavior: 'smooth' });
            }
            if (item && item.name === 'Bảng Phí') {
                this.$router.push('/#section-fee-table-1');
                element = document.querySelector('#section-fee-table-1');
                height = 55
                window.scrollTo({ top: element?.getBoundingClientRect().top + window.scrollY - height, behavior: 'smooth' });
            }
            if (item && item.name === 'CMTC Doanh Nghiệp' && item === '/') {
                this.$router.push('/#section-cmtcdn-web');
                element = document.querySelector('#section-cmtcdn-web');
                height = 80
                window.scrollTo({ top: element?.getBoundingClientRect().top + window.scrollY - height, behavior: 'smooth' });
            }
        },
        scrollToSection (e) {
            const id = e.target.parentElement.attributes[0].value
            const item = this.flattenData.find(x=>x.id === id);
            let element = null
            let height = 0
            if (item && item.name === 'Giới Thiệu') {
                this.$router.push('/#section-about-web');
                element = document.querySelector('#section-about-web');
                height = 75
                window.scrollTo({ top: element?.getBoundingClientRect().top + window.scrollY - height, behavior: 'smooth' });
            }
            else if (item && item.name === 'Bảng Phí') {
                this.$router.push('/#section-fee-table-1');
                element = document.querySelector('#section-fee-table-1');
                height = 55
                window.scrollTo({ top: element?.getBoundingClientRect().top + window.scrollY - height, behavior: 'smooth' });
            }
            else if (item && item.name === 'CMTC Doanh Nghiệp' && item === '/') {
                this.$router.push('/#section-cmtcdn-web');
                element = document.querySelector('#section-cmtcdn-web');
                height = 80
                window.scrollTo({ top: element?.getBoundingClientRect().top + window.scrollY - height, behavior: 'smooth' });
            }
        }
    }
}
