
import brandData from '~/data/brand-logo.json'
import SectionTitle from '@/components/SectionTitle.vue'
export default {
    components:{
        SectionTitle
    },
    props: ['titleSection'],
    data() {
        const branchNames = brandData.filter(x => x.isAct)
        return {
            branchNames,

            swiperOption: {
                speed: 1000,
                loop: true,
                spaceBetween: 30,
                grabCursor: true,
                slidesPerView: 6,
                autoplay: true,
                breakpoints: {
                    1200: {
                        slidesPerView: 6
                    },
                    992: {
                        slidesPerView: 5
                    },
                    768: {
                        slidesPerView: 4
                    },
                    576: {
                        slidesPerView: 3
                    },
                    320: {
                        slidesPerView: 2
                    }
                }
            }
        }
    },
};
