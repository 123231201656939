
import MobileNavigation from '@/components/MobileNavigation.vue'
export default {
    props: ['lstNavigation', 'posts'],
    components: {
        MobileNavigation
    },
    
    methods: {
        // offcanvas menu close
        mobiletoggleClass(addRemoveClass, className) {
            const el = document.querySelector('#offcanvas-menu');
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        }
    }
};
