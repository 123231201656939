import { render, staticRenderFns } from "./BlogSectionOne.vue?vue&type=template&id=18b519f2&scoped=true&"
import script from "./BlogSectionOne.vue?vue&type=script&lang=ts&"
export * from "./BlogSectionOne.vue?vue&type=script&lang=ts&"
import style0 from "./BlogSectionOne.vue?vue&type=style&index=0&id=18b519f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18b519f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/SectionTitle.vue').default,BlogGridItem: require('C:/Users/anhdu/Desktop/dichvustk/prjservicesbank/components/BlogGridItem.vue').default})
