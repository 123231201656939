
    export default {
        layout: 'error',
        props: ['error'],
        head(){
            return{
                title: this.$t('NOT.FOUND.PAGE'),
                meta: [
                    {
                        property: 'description',
                        hid: 'description',
                        name: 'description',
                        content: this.$t('NOT.FOUND.PAGE')
                    },
                    { 
                        name: 'Abstract', 
                        content: this.$t('NOT.FOUND.PAGE')
                    },
                    {
                        property: 'og:description',
                        name: 'og:description',
                        content: this.$t('NOT.FOUND.PAGE')
                    },
                ]
            }
        }
    }
