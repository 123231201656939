
import Error from '@/layouts/error.vue'
export default {
    components:{
        Error
    },
    mounted () {
        if (process.env.NODE_ENV === 'production') {
        this.$ga.page(this.$router);
        }
    }
}
