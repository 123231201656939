
import PriceGridItem from '@/components/PriceGridItem.vue'
import SectionTitle from '@/components/SectionTitle.vue'
export default {
    props: ['postPriceServices'],
    data() {
        return {
        }
    },

    components: {
        PriceGridItem,
        SectionTitle
    },
};
