
import Vue from 'vue'
import CommentCount from '@/components/widgets/CommentCountPlugin.vue'
export default Vue.extend({
    props: ['blog'],
    components: {
        CommentCount
    },
    methods: {
        randomViewNo () {
            return Math.floor(Math.random() * 10) + 20;
        },
        buildUrl (slug) {
            const hostName = process.env.NODE_ENV === 'production' ? 'https://dichvubanker.com/blog' : 'http://localhost:8952/blog'
            return `${hostName}/${slug}`
        }
    }
})
