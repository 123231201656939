
import Navigation from '@/components/Navigation.vue'
export default {
    props: ["lstNavigateItems", "posts"],
    components: {
        Navigation
    },
    data() {
        return {
            isSticky: false,
        }
    },
    mounted(){
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 200){
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        })
    },

    methods: {
        // offcanvas mobile menu
        mobiletoggleClass(addRemoveClass, className) {
            const el = document.querySelector('#offcanvas-menu');
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },

        //offcanvas search 
        toggleClass(addRemoveClass, className) {
            const el = document.querySelector('#search-popup');
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
    },
};
